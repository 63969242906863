<template>
  <div class="main" >
    <Jumbotron
    title="rassegna stampa"
    img="Luca-Garlaschelli-rassegna.jpg"
  />
    <div class="container">
      <h5>RASSEGNA STAMPA</h5>

      <div class="bio row d-flex"
      :class="{'isModal' : showModal}">

        <div 
        v-for="(img, i) in imgsArr"
        :key="i"
        class="col-sm-6 col-md-4 col-lg-3 text-center box-thumb"
        @click="goToShowModal(i)">
          <img class="img-thumb" :src="`http://www.lucagarlaschelli.com/recensioni/${img.img}`" />
          <h6>{{ img.label }}</h6>
        </div>


      </div>

    </div>

        <div 
          v-show="showModal"
          class="modal-container"
          @click="showModal = false"
        >
        {{ toggleScrollBar }}
          <img  :src="`http://www.lucagarlaschelli.com/recensioni/${imgsArr[idImg].img}`" />
        </div>

  </div>
</template>

<script>
import Jumbotron from '@/components/Jumbotron';



export default { 
  name: 'Rassegna',
  components:{
    Jumbotron,
  },
  props:{
    rassegna:Array
  },
  methods: {
    goToShowModal(i){
      this.idImg = i;
      this.showModal = true;
    },
    getImage(){
      return require('@/assets/images/recensioni/'+this.imgsArr[this.idImg]);
    }
  },
  computed:{
    toggleScrollBar(){
      const el = document.body;
      if(this.showModal) 
      {
        el.classList.add('isModal');
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
      else el.classList.remove('isModal');
      return true;
    }
  },
  mounted () {
     
    },
  data(){
    return{

      showModal: false,
      idImg: 0,
      imgsArr:[
            {
              img:"Acqua-min.jpeg",
              label:"Programma di sala Spazio Teatro NO HMA Teresa Pomodoro Sull'Acqua"
            },
            {
              img:"Agosto.jpeg",
              label:"La Stampa 17 Agosto 2012.jpg"
            },
            {
              img:"Forget-min.jpeg",
              label:"Presentazione cd Don't Forget Musikorchestra Luca Garlaschelli"
            },
            {
              img:"Gatto-min.jpeg",
              label:"Recensione Jazz Italia Gerlando Gatto Mingus in Strings"
            },
            {
              img:"Jazzit-min.jpeg",
              label:"Intervista su Jazz it a Luca Garlaschelli"
            },
            {
              img:"Liberta-min.jpeg",
              label:"Intervista a Luca Garlaschelli sul quotidiano Liberta"
            },
            {
              img:"Michelone-min.jpeg",
              label:"Recensione Alias Manifesto Guido Michelone di Mingus in Strings"
            },
            {
              img:"Mingus-min.jpeg",
              label:"Presentazione su quotidiano Liberta Mingus in strings"
            },
            {
              img:"mingus-min.jpg",
              label:"Intervista a Luca Garlaschelli sul quotidiano Liberta di Piacenza su Mingus in strings"
            },
            {
              img:"Musikorchestra-min.jpeg",
              label:"Intervista su Rinascita a Luca Garlaschelli sul cd Mai tardi della Musikorchestra"
            },
            {
              img:"Piacenza-min.jpeg",
              label:"Recensione del concerto del duo Louis Agudo Luca Garlaschelli Liberta di Piacenza"
            },
            {
              img:"Pomodoro-min.jpeg",
              label:"Programma di sala Spazio Teatro NO HMA Teresa Pomodoro Magellano"
            },
            {
              img:"Sessa-min.jpeg",
              label:"Recensione Musica Jazz  di Claudio Sessa Don't Forget della Muisikorchestra di Luca Garlaschelli"
            },
            {
              img:"Stampa-min.jpeg",
              label:"Recensione del La Stampa concerto Mingus in Strings"
            },
            {
              img:"strings-min.jpeg",
              label:"Presentazione su quotidiano Liberta Mingus in strings"
            },
            {
              img:"cochi.jpeg",
              label:"Intervista a Cochi Ponzoni su Storie di Resistenza su Liberta di Piacenza"
            },

          ]
    }
  }
}
</script>

<style lang="scss" scoped>
.main{
   margin: 45px 0;
   
}
.bio{
  text-align: justify;
  margin-top:20px;
  &.isModal{
     overflow: hidden;
   }
}
hr{
  color: white;
}
img{
  width: 250px;
  display: block;
}
.box-thumb{
  margin-bottom: 15px;
  
}
.img-thumb{
  width: 100%;
  margin-bottom: 10px;
  border-radius: 10px;
  box-shadow: 0 10px 10px rgba(0,0,0,.2);
  transition: all 0.3s;
  cursor: pointer;
  &:hover{
      transform: scale(1.05,1.05);
      box-shadow: 0 0px 10px rgba(0,0,0,.2);
    }
}
.modal-container{
  position: absolute;
  top:0;
  left: 0;
  background-color: rgba(0,0,0,.8);
  width: 100%;
  height: 100vh;
  transition: all;
  padding: 5%;
  text-align: center;
  overflow-y: auto;
  img{
    display: inline-block;
    margin: 0 auto;
    width: 80%;
    border-radius: 10px;
    box-shadow: 0 0px 10px rgba(white,.3);
  }
}

</style>